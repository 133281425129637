























import { Component, Vue, Prop } from 'vue-property-decorator';

import { Listing } from 'client-website-ts-library/types';

@Component
export default class SocialShare extends Vue {
  @Prop({ required: true })
  private readonly listing!: Listing;

  getURL(): string {
    return window.location.href;
  }
}
